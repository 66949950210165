<template>
  <div class="association-container main-cnt">
    <div class="title">社群列表</div>
    <div class="content">
      <common-table
        ref="tableRef"
        tableHeight="calc(100vh - 320px)"
        :ischeck="false"
        :filters="filters"
        :ispaging="true"
        :apiName="VipApi.communityList"
        :columns="tableColumns"
        @edit="openDialog"
        @vipClick="openVipDialog"
        @statusChange="changeAssociationStatus"
      >
        <template #operate>
          <el-button
            type="primary"
            round
            @click="openDialog"
            v-if="authData.indexOf('c_DQXZ02wTc4WFeYhsS1loL8p63dv9') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增社群</el-button
          >
        </template>
      </common-table>
    </div>
    <w-dialog
      ref="dialogRef"
      class="vip-dialog"
      :title="ruleForm.mc_id ? '编辑社群' : '新增社群'"
      width="50%"
      btnWidth="140px"
      top="20vh"
      :confirmText="ruleForm.mc_id ? '确认编辑' : '确认新增'"
      @wConfirm="handleSure"
    >
      <el-form
        class="add-form"
        ref="formRef"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="社群名称" prop="mc_name">
              <el-input
                v-model="ruleForm.mc_name"
                clearable
                placeholder="请输入社群名称"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item label="联系方式" prop="mc_contact">
              <el-input
                v-model="ruleForm.mc_contact"
                placeholder="请输入联系方式"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="社群会长" prop="mc_president">
              <el-select
                v-model="ruleForm.mc_president"
                placeholder="请输入人员姓名查询"
                filterable
                remote
                :remote-method="getUserOptions"
              >
                <el-option
                  v-for="item in options"
                  :key="item.m_id"
                  :label="item.m_realname"
                  :value="item.m_id"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20" v-if="ruleForm.mc_id">
          <el-col :span="24">
            <el-form-item label="会长简介" prop="mc_president_desc">
              <el-input
                v-model="ruleForm.mc_president_desc"
                clearable
                type="textarea"
                rows="3"
                placeholder="请输入会长简介"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="社群简介" prop="mc_desc">
              <el-input
                v-model="ruleForm.mc_desc"
                clearable
                type="textarea"
                rows="3"
                placeholder="请输入社群简介"
              ></el-input> </el-form-item></el-col
        ></el-row>
      </el-form>
    </w-dialog>
    <w-dialog
      ref="vipDialogRef"
      title="会员列表"
      width="80%"
      top="15vh"
      :hideFooter="true"
    >
      <common-table
        ref="vipTableRef"
        :ischeck="false"
        :ispaging="false"
        :tableData="vipTableData"
        :columns="vipTableColumns"
      >
        <template #president="{ row }">
          <template v-if="row.president == 1"><span>会长</span></template>
          <template v-else>
            <el-popconfirm
              title="确定要将该会员设置成会长吗?"
              @confirm="changePresidentStatus(row)"
            >
              <template #reference>
                <el-switch
                  v-model="row.president"
                  inline-prompt
                  active-text="是"
                  inactive-text="否"
                  :width="45"
                  :active-value="1"
                  :inactive-value="2"
                  :before-change="() => false"
                />
              </template> </el-popconfirm
          ></template>
        </template>
      </common-table>
    </w-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, nextTick, computed, watch } from "vue";
import { VipApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { checkStr } from "@/utils/common.js";
import { useStore } from "vuex";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
onMounted(() => {
  tableRef.value.tableLoad();
});

const filters = ref([
  {
    filterType: "search",
    name: "keyword",
    value: "",
    placeholder: "搜索社群名称",
  },
]);
/** 表格对象 */
const tableRef = ref(null);

/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "mc_id",
    label: "社群ID",
    color: "--text-third-color",
  },
  {
    prop: "mc_name",
    label: "社群名称",
  },
  {
    prop: "president",
    label: "会长姓名",
    objKey: "m_realname",
    type: "obj",
  },
  {
    prop: "mc_contact",
    label: "联系方式",
    color: "--text-third-color",
  },
  {
    type: "block",
    prop: "member_count",
    label: "会员人数",
    active: "vipClick",
    minWidth: 80,
    token: "c_BZxnIHSdJDgs9QAU8tYMPzcWrhV3",
  },
  {
    prop: "mc_status",
    type: "switch",
    label: "社群状态",
    token: "c_VOZLQN8qk0IpG3UhBRP7EJ5Tcge2",
  },
  {
    color: "--text-third-color",
    prop: "cuser",
    objKey: "u_name",
    type: "obj",
    label: "创建人",
  },
  {
    prop: "mc_ctime",
    label: "创建时间",
    color: "--text-third-color",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "c_LhoNrnYQz5dCgjetK346f0VB9a8I",
      },
    ],
  },
]);
function changeAssociationStatus(row) {
  let data = {
    mc_id: row.mc_id,
    mc_status: row.mc_status == 1 ? 2 : 1,
  };
  VipApi.setCommunityStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("操作成功！");
      tableRef.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}
const vipTableColumns = ref([
  {
    prop: "m_id",
    label: "会员ID",
    color: "--text-third-color",
    showTooltip: true,
  },
  {
    prop: "m_realname",
    label: "会员姓名",
  },
  {
    prop: "m_mobile",
    label: "联系方式",
    color: "--text-third-color",
  },
  {
    prop: "m_sex",
    label: "会员性别",
    type: "option",
    metaData: ["未知", "男", "女"],
    colorData: [
      "var(--text-third-color)",
      "var(--text-third-color)",
      "var(--text-third-color)",
    ],
  },
  {
    prop: "m_birth",
    label: "会员生日",
  },
  {
    prop: "m_weixin",
    label: "微信账号",
    color: "--text-third-color",
  },
  {
    prop: "president",
    type: "customRender",
    label: "会长设置",
  },
  {
    prop: "platform_text",
    label: "会员来源",
    color: "--text-third-color",
  },

  {
    prop: "m_company",
    prop2: "m_position",
    type: "connect",
    label: "公司岗位",
    color: "--text-third-color",
    connectText: "-",
  },
]);

const vipTableData = ref([]);
const vipDialogRef = ref(null);
const vipTableRef = ref(null);
const rowId = ref(null);
function getVipList(id) {
  VipApi.communityMembers({ mc_id: id ? id : rowId.value }).then((res) => {
    if (res.Code === 200) {
      vipTableData.value = res.Data;
      nextTick(() => {
        if (vipTableData.value && vipTableData.value.length != 0) {
          vipTableRef.value.tableLoad();
        }
      });
    }
  });
}
function openVipDialog(row) {
  vipDialogRef.value.show();
  rowId.value = row.mc_id;
  getVipList(row.mc_id);
}

function changePresidentStatus(row) {
  let data = {
    mc_id: rowId.value,
    mc_president: row.m_id,
  };
  VipApi.setPresident(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("操作成功！");
      getVipList();
    } else {
      let msg = res.Message ? res.Message : "操作失败！";
      ElMessage.error(msg);
    }
  });
}
const dialogRef = ref(null);
const formRef = ref(null);

const ruleForm = ref({
  mc_name: "",
  mc_president: "",
  mc_contact: "",
  mc_president_desc: "",
  mc_desc: "",
});
const rules = reactive({
  mc_name: [
    {
      required: true,
      message: "请输入社群名称",
      trigger: "blur",
    },
  ],
  mc_contact: [
    {
      required: true,
      message: "请输入联系方式",
      trigger: "blur",
    },
  ],
  mc_president: [
    {
      required: true,
      message: "请输入社群会长",
      trigger: "blur",
    },
  ],
});
const options = ref([]);

function getUserOptions(query) {
  VipApi.getPresidentOptions({
    keyword: query,
  }).then((res) => {
    if (res.Code === 200) {
      options.value = res.Data || [];
    } else {
      let msg = res.Message ? res.Message : "获取失败！";
      ElMessage.error(msg);
    }
  });
}
const openDialog = (row) => {
  getUserOptions();
  if (row.mc_id) {
    //编辑
    const data = JSON.parse(JSON.stringify(row));
    ruleForm.value = data;
  } else {
    // 新增
    ruleForm.value = {
      mc_name: "",
      mc_president: "",
      mc_contact: "",
      mc_president_desc: "",
      mc_desc: "",
    };
  }
  dialogRef.value.show();
};
/** 新增/编辑 确认提交 */
const handleSure = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      dialogRef.value.isLoading = true;
      const data = JSON.parse(JSON.stringify(ruleForm.value));
      if (!checkStr(data.mc_contact, "phone")) {
        ElMessage.warning("联系方式格式错误！");
        dialogRef.value.isLoading = false;
        return false;
      }
      let parmas = {
        mc_name: data.mc_name,
        mc_contact: data.mc_contact,
        mc_president: data.mc_president,
        mc_desc: data.mc_desc,
      };
      let url = "";
      if (data.mc_id) {
        // 编辑
        url = "communityUpdate";
        parmas["mc_id"] = data.mc_id;
        parmas["mc_president_desc"] = data.mc_president_desc;
      } else {
        // 新增
        url = "communityAdd";
      }
      VipApi[url](parmas).then((res) => {
        dialogRef.value.isLoading = false;
        if (res.Code === 200) {
          ElMessage.success(`操作成功！`);
          dialogRef.value.close();
          tableRef.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : `操作失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};
</script>
<style lang="scss">
.association-container {
  font-family: "Source Han Sans CN";
  .content {
    padding: 15px 20px 20px;
  }
  .vip-dialog {
    .el-dialog {
      min-width: 600px;
      .el-dialog__body {
        padding: 30px;
        .el-divider--horizontal {
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
